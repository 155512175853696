import PropTypes from "prop-types";
import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby"; // to query for image data
import Img from "gatsby-image";
import Scroll from "react-anchor-link-smooth-scroll";
import "../assets/sass/main.scss";

const Home = props => {
  const fotos = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "logopromerc.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <section id="intro" className="wrapper style1 fullscreen fade-up">
      <div className="inner">
        <Img fadeIn="false" loading="eager" className="logo" fluid={fotos.image1.childImageSharp.fluid} />
        <p className="descrip">{props.description}</p>
        <ul className="actions">
          <li>
            <Scroll href="#one" className="button">
              Ver Más
            </Scroll>
          </li>
        </ul>
      </div>
    </section>
  );
};
Home.propTypes = {
  description: PropTypes.string
};

export default Home;
