import PropTypes from "prop-types";
import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby"; // to query for image data
import BackgroundImage from "gatsby-background-image";
import Scroll from "react-anchor-link-smooth-scroll";
import "../assets/sass/main.scss";

const Productos = props => {
  const fotos = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "aceitedeoliva.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "vinos.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3: file(relativePath: { eq: "wine.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image4: file(relativePath: { eq: "arroz.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image5: file(relativePath: { eq: "maqui.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image6: file(relativePath: { eq: "mariscos.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image7: file(relativePath: { eq: "vegetables.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image8: file(relativePath: { eq: "nuts.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image9: file(relativePath: { eq: "carne.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image10: file(relativePath: { eq: "alfajor.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image11: file(relativePath: { eq: "bottle.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image12: file(relativePath: { eq: "sauces.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image13: file(relativePath: { eq: "cocodrilo.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image14: file(relativePath: { eq: "insumosmedicos.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Scroll href="#three">
      <div className="features">
        <BackgroundImage className="image" fluid={fotos.image1.childImageSharp.fluid}>
          <div className="post-card-content">
            <h2 className="post-card-title">Aceite de Oliva</h2>
          </div>
        </BackgroundImage>
        <BackgroundImage className="image" fluid={fotos.image2.childImageSharp.fluid}>
          <div className="post-card-content">
            <h2 className="post-card-title">Vinos</h2>
          </div>
        </BackgroundImage>
        <BackgroundImage className="image" fluid={fotos.image3.childImageSharp.fluid}>
          <div className="post-card-content">
            <h2 className="post-card-title">Vinos sin alcohol</h2>
          </div>
        </BackgroundImage>
        <BackgroundImage className="image" fluid={fotos.image4.childImageSharp.fluid}>
          <div className="post-card-content">
            <h2 className="post-card-title">Arroz</h2>
          </div>
        </BackgroundImage>
        <BackgroundImage className="image" fluid={fotos.image5.childImageSharp.fluid}>
          <div className="post-card-content">
            <h2 className="post-card-title">Maqui</h2>
          </div>
        </BackgroundImage>
        <BackgroundImage className="image" fluid={fotos.image6.childImageSharp.fluid}>
          <div className="post-card-content">
            <h2 className="post-card-title">Seafood</h2>
          </div>
        </BackgroundImage>
        <BackgroundImage className="image" fluid={fotos.image7.childImageSharp.fluid}>
          <div className="post-card-content">
            <h2 className="post-card-title">Productos Orgánicos</h2>
          </div>
        </BackgroundImage>
        <BackgroundImage className="image" fluid={fotos.image8.childImageSharp.fluid}>
          <div className="post-card-content">
            <h2 className="post-card-title">Frutos Secos</h2>
          </div>
        </BackgroundImage>
        <BackgroundImage className="image" fluid={fotos.image9.childImageSharp.fluid}>
          <div className="post-card-content">
            <h2 className="post-card-title">Carne de Canguro</h2>
          </div>
        </BackgroundImage>
        <BackgroundImage className="image" fluid={fotos.image13.childImageSharp.fluid}>
          <div className="post-card-content">
            <h2 className="post-card-title">Carne de Cocodrilo</h2>
          </div>
        </BackgroundImage>
        <BackgroundImage className="image" fluid={fotos.image14.childImageSharp.fluid}>
          <div className="post-card-content">
            <h2 className="post-card-title">Insumos de Seguridad Médica</h2>
          </div>
        </BackgroundImage>
        <BackgroundImage className="image" fluid={fotos.image11.childImageSharp.fluid}>
          <div className="post-card-content">
            <h2 className="post-card-title">Agua Mineral</h2>
          </div>
        </BackgroundImage>
        <BackgroundImage className="image" fluid={fotos.image12.childImageSharp.fluid}>
          <div className="post-card-content">
            <h2 className="post-card-title">Salsas y Aderezos Argentinos</h2>
          </div>
        </BackgroundImage>
        <BackgroundImage className="image" fluid={fotos.image10.childImageSharp.fluid}>
          <div className="post-card-content">
            <h2 className="post-card-title">Alfajores Argentinos</h2>
          </div>
        </BackgroundImage>
      </div>
    </Scroll>
  );
};

Productos.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node
};

export default Productos;
