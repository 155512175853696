import {
  faTwitter,
  faInstagram,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { graphql, Link, useStaticQuery } from "gatsby"
// import { Img } from "gatsby-image";
import React from "react"
import Scroll from "react-anchor-link-smooth-scroll"
import Markdown from "react-markdown/with-html"
import Sidebar from "../components/sidebar"
import Home from "../components/home"
import Spotlight from "../components/spotlight"
import Layout from "../templates/layout"
import Productos from "../components/productos"

const IndexPage = () => {
  const { dataJson } = useStaticQuery(
    graphql`
      {
        dataJson {
          siteMetadata {
            title
            description
            contact {
              socialMedia {
                instagram
                facebook
              }
              email
              phone
              address {
                key
                value
              }
            }
          }
          logo {
            img
          }
          productos {
            to
            title
            image
          }
          features {
            body
            features {
              body
              icon
              title
              image
              to
            }
          }
          spotlights {
            body
            image
            title
            to
          }
          contact
        }
      }
    `
  )
  const { siteMetadata } = dataJson
  return (
    <Layout title="Home">
      <Sidebar />
      <div id="wrapper">
        <Home description={siteMetadata.description} />

        <section id="one" className="wrapper style2 spotlights">
          {dataJson.spotlights.map(spotlight => (
            <Spotlight
              key={spotlight.title}
              title={spotlight.title}
              to={spotlight.to}
              image={spotlight.image}
            >
              {spotlight.body}
            </Spotlight>
          ))}
        </section>
        {/* <section id="two" className="wrapper style2 spotlights">
          {dataJson.productos.map(producto => (
            <Productos key={producto.title} title={producto.title} to={producto.to} image={producto.image} />
          ))}
        </section> */}
        <section id="two" className="wrapper style1-alt fade-up">
          <div className="inner">
            <h2>Principales Líneas de Producto Representadas</h2>
            <div>
              <Markdown>{dataJson.features.body}</Markdown>
            </div>
            <Productos />
          </div>
        </section>

        <section id="three" className="wrapper style1 fade-up">
          <div className="inner">
            <h2>Contáctenos</h2>
            <div>
              <Markdown>{dataJson.contact}</Markdown>
            </div>
            <div className="split style1">
              <section>
                <form
                  name="contacto"
                  method="post"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <div className="fields">
                    <div className="field half">
                      <label htmlFor="name">Nombre</label>
                      <input type="text" name="name" id="name" />
                    </div>
                    <div className="field half">
                      <label htmlFor="email">Email</label>
                      <input type="text" name="email" id="email" />
                    </div>
                    <div className="field half">
                      <label htmlFor="empresa">Empresa</label>
                      <input type="text" name="empresa" id="empresa" />
                    </div>
                    <div className="field half">
                      <label htmlFor="cargo">Cargo</label>
                      <input type="text" name="cargo" id="cargo" />
                    </div>
                    <div className="field half">
                      <label htmlFor="pais">País</label>
                      <input type="text" name="pais" id="pais" />
                    </div>
                    <div className="field half">
                      <label htmlFor="ciudad">Ciudad</label>
                      <input type="text" name="ciudad" id="ciudad" />
                    </div>
                    <div className="field">
                      <label htmlFor="message">
                        Líneas de productos de interés y observaciones
                      </label>
                      <textarea
                        style={{
                          resize: "vertical",
                          minHeight: "100px",
                        }}
                        name="message"
                        id="message"
                        rows="4"
                      />
                    </div>
                  </div>
                  <ul className="actions">
                    <li>
                      <a href="/#" className="button submit">
                        Enviar Mensaje
                      </a>
                    </li>
                  </ul>
                </form>
              </section>
              <section>
                <ul className="contact">
                  {siteMetadata.contact.address.length > 0 && (
                    <li>
                      <h3>Dirección</h3>
                      <span>
                        {siteMetadata.contact.address.map((line, index) => (
                          <React.Fragment key={line.key || index}>
                            {index !== 0 && <br />}
                            {line.value}
                          </React.Fragment>
                        ))}
                      </span>
                    </li>
                  )}
                  {siteMetadata.contact.email && (
                    <li>
                      <h3>Email</h3>
                      <a href={`mailto:${siteMetadata.contact.email}`}>
                        {siteMetadata.contact.email}
                      </a>
                    </li>
                  )}
                  {siteMetadata.contact.phone && (
                    <li>
                      <h3>Teléfono</h3>
                      <a href={`tel:${siteMetadata.contact.phone}`}>
                        {siteMetadata.contact.phone}
                      </a>
                    </li>
                  )}
                  <li>
                    <h3>Social</h3>
                    <ul className="icons">
                      {siteMetadata.contact.socialMedia.instagram && (
                        <li>
                          <a
                            title="Instagram"
                            href={siteMetadata.contact.socialMedia.instagram}
                          >
                            <FontAwesomeIcon
                              icon={faInstagram}
                              className="fa-2x"
                            />
                            <span className="label">Instagram</span>
                          </a>
                        </li>
                      )}
                      {/* {siteMetadata.contact.socialMedia.facebook && (
                        <li>
                          <a title="Facebook" href={siteMetadata.contact.socialMedia.facebook}>
                            <FontAwesomeIcon icon={faFacebook} className="fa-2x" />
                            <span className="label">Facebook</span>
                          </a>
                        </li>
                      )} */}
                    </ul>
                  </li>
                </ul>
              </section>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}
export default IndexPage
